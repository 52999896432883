import React, { useEffect } from "react";

import AdminLayout from "./components/AdminLayout/Admin";
import ForgotPassword from "./components/ForgotPassword";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
// import 'react-select/dist/react-select.css';
import { Navigate, Route, Routes } from "react-router-dom";

import axios from "axios";
import routes from "./components/AdminLayout/routes";
//add the following lines to any file where you import axios
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

function App() {
  useEffect(() => {
    if (window.dataLayer) {
      // get user_default_email from local storage
      const user_default_email = localStorage.getItem("user_default_email");
      if (user_default_email){
      window.dataLayer.push({
        event: "start_pw",
        pw_user_email: user_default_email,
      });
    }
    else{
      window.dataLayer.push({
        event: "start_pw",
      });

    }
    }
    // Remove any existing chat scripts
    const existingScript = document.getElementById("tawkToScript");
    if (existingScript) {
      existingScript.remove();
    }

    // Remove the charla widget script if it exists
    const existingCharlaScript = document.querySelector('charla-widget');
    if (existingCharlaScript) {
      existingCharlaScript.remove();
    }

    // Insert the new chat widget script
    const newChatWidgetScript = document.createElement("script");
    newChatWidgetScript.id = 'anw2-sdk-GEHlG5d1azeJzDWtf5WkgQ';
    newChatWidgetScript.src = 'https://api.anychat.one/widget2/load?id=1be54118-7597-376b-89cc-35ad7f95a481&r=' + encodeURIComponent(window.location);

    // newChatWidgetScript.innerHTML = `(function(d, s, id){
    //   var js, fjs = d.getElementsByTagName(s)[0];
    //   if (d.getElementById(id)) return;
    //   js = d.createElement(s); js.id = id;
    //   js.src = 'https://api.anychat.one/widget2/load?id=1be54118-7597-376b-89cc-35ad7f95a481&r=' + encodeURIComponent(window.location);
    //   fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'anw2-sdk-GEHlG5d1azeJzDWtf5WkgQ'));`;

    // Insert the new script into the document
    const scriptParentNode = document.getElementsByTagName("script")[0];
    scriptParentNode.parentNode.insertBefore(newChatWidgetScript, scriptParentNode);

    // Clean up the script when the component unmounts
    return () => {
      const existingNewChatScript = document.getElementById('anw2-sdk-GEHlG5d1azeJzDWtf5WkgQ');
      if (existingNewChatScript) {
        existingNewChatScript.remove();
      }
    };
  }, []);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/user" && prop.component) {
        const Element = prop.component;
        if (
          localStorage.getItem("access_token") ||
          prop.path === "/user/change-password"
        ) {
          return (
            <Route
              path={prop.path}
              element={<Element />}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
        />
        <Route
          path="/reset-password"
          element={<ResetPassword />}
        />

        <Route
          path="/user"
          element={<AdminLayout />}>
          {getRoutes(routes)}
        </Route>

        <Route
          path="/*"
          element={<Navigate to="/login" />}
        />
      </Routes>
    </div>
  );
}

export default App;
